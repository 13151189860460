<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 공사현장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-dept
            :plantCd="searchParam.plantCd"
            type="search"
            :label="patrolInfo.patrolTypeCd == 'PTC0000001' ? '점검 주관부서' : '점검 대상부서'"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" v-show="patrolInfo.patrolTypeCd == 'PTC0000001'">
          <c-vendor
            label="점검 대상업체"
            name="vendorCd"
            v-model="searchParam.vendorCd"
            :selfFlag="false"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 진행단계 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="진행단계"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-datepicker
            :range="true"
            label="점검기간"
            defaultStart="-1M"
            defaultEnd="0M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          title=""
          class="qtable-card"
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.patrolCompleteFlag)]">
                    {{ props.row.patrolCompleteFlagName }}
                  </div>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ patrolInfo.patrolTypeCd == 'PTC0000001' ? props.row.vendorName : props.row.deptName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.patrolDate }}
                  </div>
                  <div class="text-grid-etc" v-show="patrolInfo.patrolTypeCd == 'PTC0000001'">
                    {{ props.row.deptName }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  name: 'patrol',
  mixins: [mixinFooterSearch],
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolTypeCd: 'PTC0000001',
          title: '협력업체 점검 목록',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'patrolName',
            field: 'patrolName',
            label: '순회명',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            label: '순회일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        vendorCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      listUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sai.patrol.list.url;

      this.searchParam.patrolTypeCd = this.patrolInfo.patrolTypeCd;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(evt, row) {
      this.popupOptions.title = (this.patrolInfo.patrolTypeCd == 'PTC0000001' ? '협력업체 점검 상세' : '관리감독자 업무일지 상세'); // 순회점검 상세
      this.popupOptions.param =  {
        plantCd: row ? row.plantCd : this.searchParam.plantCd,
        saiPatrolId: row ? row.saiPatrolId : '',
        patrolTypeCd: row ? row.patrolTypeCd : this.patrolInfo.patrolTypeCd
      };
      this.popupOptions.target = () => import(`${'./patrolDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      let cls = '';
      switch(cd) {
        case 'Y': // 점검완료
          cls = 'txt-box-grid text-positive-box';
          break;
        default: // 점검중
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
  }
};
</script>
